import { Timestamp } from "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from '../../firebase-config.js';
import DedicatedTweet from "../posts/DedicatedTweet.js";
import DedicatedImage from "../posts/DedicatedImage";
import DedicatedVideo from "../posts/DedicatedVideo";
// import Comment from "../components/Comment.js";
import {getPost, deletePost, sendAnonymousMessage} from "../../api/ForumApi";

// http://localhost:3000/posts/CvqdLhNbgLvJoksv9v7H
const Posts = ({isAuth, isPrivate}) => {
  let navigate = useNavigate();
  const [post,setPost] = useState({
    author:{
      name:"loading content"
    },
    timeStamp:Timestamp.now()
  });
  const params = useParams();
  const anonMessage = useRef("");
  //Unsent, Success, Failure because of network issues
  const [anonMessageStatus, setAnonMessageStatus] = useState("Unsent");


  // const [commentId,setCommentId]=useState(params.id);
  
  function convertToDate(timeStamp){
    var theDate = new Date(timeStamp.seconds*1000);
    return theDate.toString().slice(4,16) 
  }

  async function trySendingAnonymousMessage() {
    if (anonMessage.current.length === 0 || anonMessageStatus === "Success"){
      return;
    }
    try {
      await sendAnonymousMessage(anonMessage.current, params.id);
      anonMessage.current = "";
      setAnonMessageStatus("Success");
    } catch {
      anonMessage.current = "";
      setAnonMessageStatus("Failure because of network issues");
    }
  }

  useEffect(()=>{
    if(window.passedData) {
      setPost(window.passedData);
    }
    else {
      getPost(params.id, isPrivate)
        .then((fetchedPost) => setPost(fetchedPost));
    }
  }, [params, isPrivate]);

  return (
    <div className="homePage">
      <div className="dedicatedPost">
        <div className="postHeader">
          <div className="title">
            <h2 style = {{"font-weight": "bold"}}>{post.title}</h2>
          </div>
          <div className="deletePost">
            {isAuth && post.author.id === auth.currentUser.uid && (
              <button
                onClick={() => {
                  deletePost(params.id, isPrivate, navigate);
                }}
              >
                {" "}
                &#128465;
              </button>
            )}
          </div>
        </div>
        {
          post.postType === "macTweet" ?
            <DedicatedTweet content = {post.content}/>:
          post.postType === "macImage" ?
            <DedicatedImage content = {post.content}/>:
          post.postType === "macVideo" ?
            <DedicatedVideo content = {post.content}/>:
          <></>
        }
        <div className="postFooter">
          <h4 className="author">@{post.author.name}</h4>
          <p>{convertToDate(post.timeStamp)}</p>
        </div>
      </div>
      {
        post.author.name === "loading content" ? <></>
        :
        <div className='anonymousMessageDiv'>
          <h3 style = {{"font-weight": "bold"}}>Send an anonymous message to the author: </h3>
          {
            anonMessageStatus === "Unsent" ?
              <div className='anonymousTextboxAndSendDiv'>
                <textarea className='anonymousMessageTextbox' placeholder = "I think your idea is flawed in this post..." onChange={
                  (event)=>{
                    anonMessage.current = event.target.value;
                  }
                }
                />
                <button onClick={trySendingAnonymousMessage}>Send</button>
              </div> :
              `Sending the message was a ${anonMessageStatus}. `
          }
          {
            anonMessageStatus === "Success" && "Author will be notified within 24 hrs."
          }
        </div>
      }
    </div>
  )
};

export default Posts;

function DedicatedImage({content}){
    return (
        <>
            <div className="mobile-image-wrapper" >
                <img className="mobilePostImageContainer" src={content.image} alt={content.alt} />
            </div>
                {content.postText!==""&&(
                    <br></br>
                )}
            
            <div className="mobile-dedicatedPostTextContainer"  style = {{'white-space':'pre-line'}}>{content.postText}</div>
        </>
        
    )
}
export default DedicatedImage;
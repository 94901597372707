import React from 'react'
import { auth,provider } from '../../firebase-config.js';
import {signInWithPopup} from "firebase/auth"
import { useNavigate } from 'react-router-dom';
const Login = ({setIsAuth}) => {
  let navigate = useNavigate();

  const signInWithGoogle = () => {
    signInWithPopup(auth,provider).then((result)=>{
      localStorage.setItem("isAuth",true);
      setIsAuth(true);
      navigate("/blog");
    })
  };
  return (
    <div className="loginPage">
      <p className='sign__word'>Sign in to the world of chaos</p>
      <button className='login-with-google-btn' onClick={signInWithGoogle}>
        Sign In With Google
      </button>
      <p class="mobile-link">Mayank Chaturvedi</p>
    </div>
  )
}
export default Login;
import React, { useState } from "react";
import "./styles.css";

const Tracker = () => {
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleButtonClick = async (buttonId) => {
        if (!navigator.onLine) {
            setResponse('No internet connection. Please check your network.');
            return;
        }

        setLoading(true);
        try {
            const response = await fetch(`https://docs.google.com/forms/d/e/1FAIpQLScg6NToVr207yo3RrhzGoZo_fF7slfD3HDLrC5J1J2DAr4e5A/formResponse?&submit=Submit?usp=pp_url&entry.1950953710=${buttonId}`);
            if (response.ok) {
                setResponse(`Response received for your health action of: ${buttonId}`);
            } else {
                throw new Error('Request failed, try again');
            }
        } catch (error) {
            // Check if the error is due to CORS
            if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
                // Ignore CORS-related errors
                setResponse(`Response received for your health action of: ${buttonId}`);
            } else {
                // Handle other errors
                setResponse(error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="tracker-app">
            <h1 className="tracker-title">Health Tracker</h1>
            {response && <p className="response">{response}</p>}
            {!response && (
                <div className="tracker-container">
                    <button className="tracker-button" onClick={() => handleButtonClick("breakfast")} disabled={loading}>Break-fast</button>
                    <button className="tracker-button" onClick={() => handleButtonClick("lunch")} disabled={loading}>Lunch</button>
                    <button className="tracker-button" onClick={() => handleButtonClick("dinner")} disabled={loading}>Dinner</button>
                    <button className="tracker-button" onClick={() => handleButtonClick("refresh")} disabled={loading}>Refresh</button>
                </div>
            )}
        </div>
    );
};


export default Tracker;

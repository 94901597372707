import React, {useState} from "react";
import './MobileApp.css';
import './NeonSign.css';
import Bts from "../common/bts/Bts";
import Tracker from "../common/health_tracker/Tracker";
import './components/MobileSpeedDial.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from "./pages/Home";
import CreatePost from "./pages/CreatePost";
import Login from "./pages/Login";
import Posts from "./pages/Posts";
import Blog from "./pages/Blog";
import MobileSpeedDial from "./components/MobileSpeedDial";
import { auth } from "../firebase-config";
import { onAuthStateChanged } from "firebase/auth";

function MobileApp() {
  const [isAuth,setIsAuth] = useState(false);
  onAuthStateChanged(auth,(user)=>{
    if(user){
      setIsAuth(true);
    }else{
      localStorage.clear();
      setIsAuth(false);
    }
  })
  return (
    <Router>
      <MobileSpeedDial isAuth={isAuth} setIsAuth={setIsAuth}/>
      <Routes>
        <Route path = "/" element={<Home />} />
        <Route path = "/bts" element={<Bts/>} />
        <Route path = "/createpost" element={<CreatePost isAuth={isAuth}/>} />
        <Route path = "/login" element={<Login setIsAuth={setIsAuth}/>} />
        <Route path = "/private/posts/:id" element={<Posts isAuth={isAuth} isPrivate={true}/>} />
        <Route path = "/posts/:id" element={<Posts isAuth={isAuth} isPrivate={false}/>} />
        <Route path = "/posts" element={<Blog isAuth={isAuth}/>} />
        <Route path = "/blog" element={<Blog isAuth={isAuth}/>} />
        <Route path = "/tracker" element={<Tracker/>} />
      </Routes>
    </Router>
  );
}

export default MobileApp;

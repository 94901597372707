import React from "react";
const Home = () => {
    return (
      <div class="mobile-sign">
        <span class="mobile-sign__word">Mac'n'Chaos</span>
        <p class="mobile-link">Mayank Chaturvedi</p>
      </div>
    )
};

export default Home;
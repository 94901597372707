import {auth, db} from '../firebase-config.js';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import {addDoc, collection, Timestamp} from "firebase/firestore";
import listApi from "./ListPostsApi";

export class CreatePostApiClass {
    constructor(title, postText, postType, isPrivate, postLink, postFile, setUploadProgress, navigate) {
        this.postText = postText;
        this.title = title;
        this.postType = (postType==="Tweet")?"macTweet": ((postType==="Picture")?"macImage": "macVideo");
        this.isPrivate = isPrivate;
        this.postFile = postFile;
        this.setUploadProgress = setUploadProgress;
        this.articleCollectionRef = collection(db,"posts"); //Place to store post
        this.mediaFolder = 'PublicMedia/'; //Place to store media
        this.postLink = postLink
        this.navigate = navigate
        if(this.isPrivate) {
            // Check if a table exists. 
            const private_collection_name = `private/posts/${auth.currentUser.uid}`;
            this.articleCollectionRef = collection(db, private_collection_name);
            this.mediaFolder = `PrivateMedia/${auth.currentUser.uid}/`
        }
    }

    async create() {
        if((this.postType === "macImage" || this.postType === "macVideo")
                && this.postLink === "") {
            await this.generateMediaLinkAndUpdateDatabase();
        } else {
            await this.updatePostInDatabase(this.postLink);
        }
    }

    async generateMediaLinkAndUpdateDatabase() {
        const storage = getStorage();
        const fileName = (Math.random() + 1).toString(36).substring(2)
        var extension = this.postFile.current.type
        extension = extension.replace(/(.*)\//g, '')
        const storageRef = ref(storage, this.mediaFolder +fileName+"."+extension);
    
        const uploadTask = uploadBytesResumable(storageRef, this.postFile.current);
    
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.setUploadProgress(progress);
                switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
                default:
                    console.log("inside default")
                }
            }, 
            (error) => {
                this.setUploadProgress(-1);
                console.log("upload task failed");
            }, 
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    this.updatePostInDatabase(downloadURL);
                });
            }
        );
    }

    async updatePostInDatabase(inputPostLink="") {
        const ctime = Timestamp.now()
        const post = {
            title: this.title,
            postType: this.postType,
            author: {
              name: auth.currentUser.displayName,
              id: auth.currentUser.uid
            },
            private: this.isPrivate,
            content: {
                postText: this.postText
            },
            timeStamp:ctime,
            updates: []
          }
        
        if(this.postType === "macImage") {
            post.content.image = inputPostLink
        }
        else if(this.postType === "macVideo") {
            post.content.url = inputPostLink;
        }
        const docRef = await addDoc(this.articleCollectionRef, post);
        post.id = docRef.id;

        // add doc, setDoc to "firebase/firestore" if you're uncommenting this
        // if(!this.isPrivate) {
        //     await setDoc(
        //         doc(db,"comments",docRef.id),
        //         {
        //           "parent":docRef.id,
        //           "children":[],
        //           "author":{
        //             name:auth.currentUser.displayName,
        //             id:auth.currentUser.uid
        //           },
        //           "timeStamp":ctime,
        //           "text":"",
        //           "post_id":docRef.id,
        //           "depth":0
        //         }
        //       );
        // }

        listApi.addElementToList(post)
        this.navigate("/blog");
    }
}


/**
 * Testing paths:-
 * 1. Public posts
 *  a. Tweets
 *  b. Images(link created in public folder)
 *      - upload image
 *      - give image link
 *  c. Videos(link created in public folder)
 *      - upload image
 *      - give image link
 * 2. Private posts
 *  a. Tweets: should create new collection for the user with appropriate rule
 *  b. Images(link created in private folder)
 *      - upload image (check if the uploaded image link always work: because we've restricted the media folder read to auth only)
 *      - give image link
 *  c. Videos(link created in private folder)
 *      - upload image (check if the uploaded image link always work: because we've restricted the media folder read to auth only)
 *      - give image link
 * 3. Check for recursive infinite calls, multiple media creation(this could harm blog in long term)
 */
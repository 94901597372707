import {auth, db} from '../firebase-config.js';
import { collection, query, limit, getDocs, orderBy, startAfter, Timestamp } from "firebase/firestore";

let instance;
let postList = [];//a list combined and fetched posts

let privatePosts = [];//queue of private posts
let publicPosts = [];//queue of public posts

let lastPrivatePostTime = Timestamp.now(); //pointer
let lastPublicPostTime = Timestamp.now(); //pointer

let noPublicPostsToFetch = false; //true if we've fetched the complete public list
let noPrivatePostsToFetch = false; //true if we've fetched the complete private list
class listPostApiClass {
    constructor() {
        if (instance) {
            throw new Error("You can create only one instance of list api class");
        }
        instance = this;
    }
    
    async fetchFromFirestore(toFetch) {
        //initialize the queues
        if (
          !noPrivatePostsToFetch &&
          auth.currentUser !== null &&
          privatePosts.length < toFetch
        ) {
          //user is logged in. We should fetch private posts
          const dataQuery = query(
            collection(db, `private/posts/${auth.currentUser.uid}`),
            limit(toFetch-privatePosts.length),
            orderBy("timeStamp", "desc"),
            startAfter(lastPrivatePostTime)
          );
          const data = await getDocs(dataQuery);
          const fetchedDocs = data.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          if(fetchedDocs.length === 0){
              noPrivatePostsToFetch = true;
          } else {
              lastPrivatePostTime = fetchedDocs[fetchedDocs.length-1].timeStamp;
          }
          privatePosts = privatePosts.concat(fetchedDocs);
        }
        if(!noPublicPostsToFetch && publicPosts.length < toFetch) {
            const dataQuery = query(
              collection(db, "posts"),
              limit(toFetch-publicPosts.length),
              orderBy("timeStamp", "desc"),
              startAfter(lastPublicPostTime)
            );
            const data = await getDocs(dataQuery);
            const fetchedDocs = data.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            if(fetchedDocs.length === 0){
                noPublicPostsToFetch = true;
            } else {
                lastPublicPostTime = fetchedDocs[fetchedDocs.length-1].timeStamp;
            }
            publicPosts = publicPosts.concat(fetchedDocs);
        }

        //Populate posts
        for(let _=0;_<toFetch && ((publicPosts.length+privatePosts.length) > 0);++_) {
            const publicTime = publicPosts[0]?.timeStamp;
            const privateTime = privatePosts[0]?.timeStamp;
            if(publicTime && privateTime) {
                if(publicTime >= privateTime) {
                    postList.push(publicPosts.shift());
                } else {
                    postList.push(privatePosts.shift());
                }
            }else if(publicTime) {
                postList.push(publicPosts.shift());
            } else if(privateTime) {
                postList.push(privatePosts.shift());
            }
        }
    }

    async getNext(currentCount, nextCount) {
        if(postList.length >= currentCount+nextCount) {
            return postList.slice(currentCount, currentCount+nextCount);
        } else {
            const toFetch = currentCount+nextCount-postList.length;
            await this.fetchFromFirestore(toFetch);
            return postList.slice(currentCount, currentCount+nextCount);
        }
    }

    noMorePostAvailable() {
        return noPublicPostsToFetch && noPrivatePostsToFetch;
    }

    getElementFromTarget(id, targetList) {
        let targetIndex = undefined;
        for(let index=0;index<targetList.length;++index){
            if(targetList[index].id === id) {
                targetIndex = index;
                break;
            }
        }
        return targetIndex
    }

    removeElementFromTarget(id, targetList) {
        const targetIndex = this.getElementFromTarget(id, targetList);
        if(targetIndex){
            targetList.splice(targetIndex);
        }
    }

    deleteElementFromLists(id) {
        this.removeElementFromTarget(id, postList);
        this.removeElementFromTarget(id, privatePosts);
        this.removeElementFromTarget(id, publicPosts);
    }

    addElementToList(newPost) {
        postList.unshift(newPost)
    }

    getElementFromLists(id) {
        let postListIndex = this.getElementFromTarget(id, postList);
        let privateListIndex = this.getElementFromTarget(id, privatePosts);
        let publicListIndex = this.getElementFromTarget(id, publicPosts);
        if(postListIndex){
            return postList[postListIndex];
        } else if(privateListIndex){
            return privatePosts[privateListIndex];
        } else if(publicListIndex){
            return publicPosts[publicListIndex];
        } else{
            return undefined;
        }
    }

    externalRedirect(id, isPrivate){
        let newWindow;
        if (!isPrivate) {
          newWindow = window.open(
            window.location.origin + "/posts/" + id,
            "_blank" // <- This is what makes it open in a new window.
          );
        } else {
          newWindow = window.open(
            window.location.origin + "/private/posts/" + id,
            "_blank" // <- This is what makes it open in a new window.
          );
        }
        newWindow.passedData = this.getElementFromLists(id);
    }
    
    internalNavigate(id, isPrivate, navigate){
        if(!isPrivate) {
            navigate(`/private/posts/${id}`);
        }
        else {
            navigate(`/posts/${id}`);
        }
    }
}

const listApi = new listPostApiClass();
export default listApi;
import React, { useState, useRef } from "react";
import { sendAnonymousMessage } from "../../api/ForumApi";
const Subscribe = () => {
  const anonMessage = useRef("");
  const [anonMessageStatus, setAnonMessageStatus] = useState("Unsent");

  async function trySendingAnonymousMessage() {
    if (anonMessage.current.length === 0 || anonMessageStatus === "Success") {
      return;
    }
    try {
      await sendAnonymousMessage(anonMessage.current, "Subscription");
      anonMessage.current = "";
      setAnonMessageStatus("Success");
    } catch {
      anonMessage.current = "";
      setAnonMessageStatus("Failed");
    }
  }

  return (
    <div>
      <p className="subscribe">Subscribe to get access</p>
      {
        <div style={{ "text-align": "center" }}>
          <h3 style={{ "font-weight": "bold" }}>Enter your email id: </h3>
          {anonMessageStatus === "Unsent" ? (
            <div>
              <input
                placeholder="email..."
                onChange={(event) => {
                  anonMessage.current = event.target.value;
                }}
              />
              <button onClick={trySendingAnonymousMessage}>Send</button>
            </div>
          ) : (
            `Sending the message was a ${anonMessageStatus}. `
          )}
          {anonMessageStatus === "Success" &&
            "You are added to the waiting list."}
        </div>
      }
    </div>
  );
};
export default Subscribe;

import listApi from "../../api/ListPostsApi.js";

function Tweet({content, postId, isPrivate}){
    // Determine the length of text for the snippet (e.g., 200 characters)
    const maxLength = 250;

    const isTrimmed = content.postText.length > maxLength;
    
    // Trim the content text to show only the snippet
    const snippet = isTrimmed ?
        `${content.postText.substring(0, maxLength).trim()}...` :
        content.postText;

    return (
        <div className="mobilePostTextContainer" style = {{'white-space':'pre-line'}}>
            <p>{snippet}</p>
            {isTrimmed &&
            <button className="mobilePostTextContainer-readMore" onClick={
                ()=>{
                    listApi.externalRedirect(postId, isPrivate)
                    }
            }>
                Read more
            </button>}
        </div>
    );
}
export default Tweet;
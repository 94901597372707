import ReactPlayer from "react-player";
function DedicatedVideo({content}){
    return (
        <div >
            <div className="mobile-player-wrapper">
                <ReactPlayer className="mobilePostVideoContainer" url={content.url} width="100%" height="100%" controls={true}/>
                
            </div>
            {content.postText!==""&&(
                    <br></br>
                )}
            <div className="mobile-dedicatedPostTextContainer"  style = {{'white-space':'pre-line'}}>{content.postText}</div>
        </div>
    )
}
export default DedicatedVideo;
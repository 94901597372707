import ReactPlayer from "react-player";
function Pvideo({content}){
    return (
        <>
            <div className="player-wrapper">
                <ReactPlayer className="postVideoContainer" url={content.url} width="100%" height="100%" controls={true}/>
            </div>
            {content.postText!==""&&(
                    <>
                    <br></br>
                    <br></br>
                    </>
                )}
            <div className="postTextContainer" style = {{'white-space':'pre-line'}}>{content.postText}</div>
        </>
    )
}
export default Pvideo;
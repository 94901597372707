import {auth, db} from '../firebase-config.js';
import { deleteDoc, doc, getDoc, Timestamp } from "firebase/firestore";
import listApi from "./ListPostsApi";

export async function deletePost(id, isPrivate, navigate) {
    const collectionName = isPrivate ? `private/posts/${auth.currentUser.uid}` : "posts";
    const postDoc = doc(db, collectionName, id);
    await deleteDoc(postDoc);
    // const commentDoc = doc(db, "comments", id);
    // await deleteDoc(commentDoc);
    listApi.deleteElementFromLists(id);
    navigate("/blog");
}

export async function getPost(id, isPrivate) {
    const elementFromList = listApi.getElementFromLists(id);
    if(elementFromList){
        return elementFromList;
    }
    const collectionName = isPrivate ? `private/posts/${auth.currentUser.uid}` : "posts";
    const docRef = doc(db, collectionName, id);
    const docSnap = await getDoc(docRef);
    return docSnap.data() || {
        author:{
          name:"Please recheck the URL. You seem to have landed in the unknown territories of the internet"
        },
        timeStamp:Timestamp.now()
      }
}

export async function sendAnonymousMessage(message, postId) {
  if (!navigator.onLine) {
      throw new Error("No internet connection");
  }
  const link = `https://docs.google.com/forms/d/e/1FAIpQLSemmyRYYrteitMQweSuYMGNkL5jBylovXSnkSx_nNPVHo2FGw/formResponse?&submit=Submit?usp=pp_url&entry.1139169563=${postId}&entry.106893739=${message}`;
  fetch(link).then((response) => {
    if (!response.ok) {
      throw new Error("HTTP status " + response.status);
    }
    return response.json();
  });
}
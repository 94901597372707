import React from "react";
const Home = () => {
    return (
      <div class="sign">
        <span class="sign__word">Mac'n'Chaos</span>
      </div>
    )
};

export default Home;

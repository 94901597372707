// import React, { useRef } from 'react';
// import { SpeedDial } from 'primereact/speeddial';
// import { Toast } from 'primereact/toast';

// export default function MobileSpeedDial() {
//     const toast = useRef(null);
//     const items = [
//         {
//             label: 'Add',
//             icon: 'pi pi-pencil',
//             command: () => {
//                 toast.current.show({ severity: 'info', summary: 'Add', detail: 'Data Added' });
//             }
//         },
//         {
//             label: 'Update',
//             icon: 'pi pi-refresh',
//             command: () => {
//                 toast.current.show({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
//             }
//         },
//         {
//             label: 'Delete',
//             icon: 'pi pi-trash',
//             command: () => {
//                 toast.current.show({ severity: 'error', summary: 'Delete', detail: 'Data Deleted' });
//             }
//         },
//         {
//             label: 'React Website',
//             icon: 'pi pi-external-link',
//             command: () => {
//                 window.location.href = 'https://facebook.github.io/react/';
//             }
//         }
//     ];

//     return (
//         <div className="card">
//             <div>
//                 <Toast ref={toast} />
//                 <SpeedDial model={items} direction="down" className="MobileSpeedDial" />
//             </div>
//         </div>
//     )
// }


import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HomeIcon from '@mui/icons-material/Home';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CreateIcon from '@mui/icons-material/Create';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { auth } from "../../firebase-config";
import { signOut } from "firebase/auth";
import {useNavigate, useLocation} from 'react-router-dom';

export default function MobileSpeedDial({isAuth,setIsAuth}) {
    const navigate = useNavigate();
    const actionMap = new Map();
    actionMap.set("snippets", { icon: <HomeIcon />, name: 'Snippets', command: () => {navigate("/blog");}});
    actionMap.set("create", { icon: <CreateIcon />, name: 'Create', command: () => {navigate("/createpost");} });
    actionMap.set("login", { icon: <LoginIcon />, name: 'LogIn', command: () => {navigate("/login");} });
    actionMap.set("logout", {
        icon: <LogoutIcon />,
        name: 'LogOut',
        command: ()=>{
            signOut(auth).then(()=>{
              localStorage.clear()
              setIsAuth(false)
              window.location.pathname = "/login"
            })
          }
    });
    const allMenu = ["snippets", "create", "login", "logout"];
    let menuItems;
    function buildMenuItems(isAuth, location) {
        menuItems = structuredClone(allMenu);
        if (isAuth) {
            menuItems = menuItems.filter(item => item !== "login");
        }
        if (!isAuth) {
            menuItems = menuItems.filter(item => item !== "logout");
            menuItems = menuItems.filter(item => item !== "create");
        }
        if(location === '/createpost') {
            menuItems = menuItems.filter(item => item !== "create");
        }
        if(location === '/blog') {
            menuItems = menuItems.filter(item => item !== "snippets");
        }
    }
    const location = useLocation();
    buildMenuItems(isAuth, location.pathname);
    useEffect(() => {
        buildMenuItems(isAuth, location.pathname);
    });

  return (
    <Box className="MobileSpeedDial">
      <SpeedDial
        ariaLabel="SpeedDial mobile macnchaos"
        sx={{ optacity: 1, position: 'fixed', top: 0 , right: 0 }}
        icon={<MoreHorizIcon className="moreHorizIconStyle"/>}
        FabProps={{
            sx: {
              bgcolor: 'transparent',
            }
          }}
        direction='down'
      >
        {menuItems.map((action) => (
          <SpeedDialAction
            key={actionMap.get(action).name}
            icon={actionMap.get(action).icon}
            tooltipTitle={actionMap.get(action).name}
            onClick={actionMap.get(action).command}
            direction='down'
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreatePostApiClass } from '../../api/CreatePostApi.js';

const CreatePost = ({ isAuth }) => {
  const [title, setTitle] = useState("");
  const [postText, setPostText] = useState("");
  const [postType, setPostType] = useState("Tweet");
  const [uploadProgress, setUploadProgress] = useState(-1);
  const hiddenFileInput = useRef(null);
  const postFile = useRef("");
  const [isPrivate, setIsPrivate] = useState(false);

  const postLink = useRef("");
  let navigate = useNavigate();

  useEffect( //can also authenticate post creator here
    ()=>{
      if(!isAuth){
        navigate("/login");
      }
    }
  );
  function handleUploadButtonClick(event){
    hiddenFileInput.current.click();
  }
  function handleFileInputChange(event){
    postFile.current = event.target.files[0];
    if(postFile.current.size>0){
      setUploadProgress(0);
    }
  }
  async function createPost() {
    setUploadProgress(0.1);
    let createPostObject = new CreatePostApiClass(title, postText, postType, isPrivate, postLink.current, postFile, setUploadProgress, navigate);
    await createPostObject.create();
  }
  return (
    <div className = "createPostPage">
      <div className = "cpContainer">
        
        <div className="postOptionSelect">
          <h1>Post a </h1>
          <select
            value={postType}
            onChange={
              (e)=>{
                setPostType(e.target.value)
              }
            } 
          >
            <option>Tweet</option>
            <option>Picture</option>
            <option>Video</option>
          </select>
        </div>
        <div className="privatePostOptionSelect">
          <div class="checkbox-wrapper-2">
              <input type="checkbox" class="sc-gJwTLC ikxBAC"
                onChange={
                  (event)=>{
                    setIsPrivate(event.target.checked);
                  }
                }
                checked={isPrivate}
              />
          </div>
          &nbsp;
          Private
        </div>
        
        <div className='inputGp'>
          <label>Title : </label>  
          <input placeholder = "Title..." onChange={
            (event)=>{
              setTitle(event.target.value)
            }
          }/>
        </div>
        <div className='inputGp'>
          <label>Post Text: </label>  
          <textarea placeholder = "Post..." onChange={
            (event)=>{
              setPostText(event.target.value)
            }
          }
          />
        </div> 
        {
          (postType==="Video" || postType==="Picture")&&
          (<div className='inputGp'>
            <label>Content URL: </label>  
            <input placeholder = "optional: https://..." onChange={
            (event)=>{
              postLink.current = event.target.value
            }
          }/>
          </div>)
        }
        {
          (postType==="Video" || postType==="Picture")&&uploadProgress===-1&&(
            <>
              <button onClick={handleUploadButtonClick} className="UploadMediaButton">
                Upload A File
              </button>
              <input type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileInputChange}
                    style={{display:'none'}} 
              /> 
            </>
          )
        }
        {
          uploadProgress>0 && (
            <div className='inputGp'>
              <label>{Math.floor(uploadProgress)}%</label>
            </div>
          )
        }
        {
          uploadProgress===0 && (
            <div className='inputGp'>
              <label>Ready to upload</label>
            </div>
          )
        }
        {
          uploadProgress>0 && (
            <div class="waitForSubmission"></div>
          )
        }
        {
          uploadProgress<=0 && (
            <button onClick={createPost}>Submit Post</button>
          )
        }
      </div>
    </div>
  )
}

export default CreatePost;
import React from "react";
import "./styles.css";
import anaytics from "./analytics.jpeg"
import Subscribe from "../subscribe/Subscribe"
import p2 from "./p2.jpeg"

const Bts = () => {
  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Beyond the Screen</title>
      <link rel="stylesheet" href="./styles.css" />
      {/* Header Section */}
      <header className="hero">
        <div className="overlay" />
        <div className="hero-content">
          <p>Embrace a life</p>
          <h1>Beyond the Screen</h1>
          <a href="#about" className="btn">
            Learn More
          </a>
        </div>
      </header>
      {/* About Section */}
      <section id="about" className="about-section">
        <div className="container">
          <h2>BTS: Beyond the Screen</h2>
          <p>
            With BTS, you can break free from the endless scroll and regain
            precious moments of your day...
          </p>
          <p>
            Elevate your well-being with our exclusive android application,
            offering forecasting, trend analysis, and anomaly detection for your
            screen time. Join our community and gamify your journey by sharing
            analytics with fellow members.
          </p>
        </div>
      </section>
      {/* Features Section */}
      <section className="features-section">
        <div className="container">
          <h2>Key Features</h2>
          <div className="features-grid">
            <div className="feature">
              <h3>Screen time analytics</h3>
              <img src={anaytics} alt="Analytics in the app" height={500}></img>
              <p>
                Get the state of the art data analytics on your screen time.
                Detect anomalies, forcast, and understand trends of your app
                usage.
              </p>
            </div>
            <div className="feature">
              <h3>BTS Groups</h3>
              <img src={p2} alt="Bts group" height={500}></img>
              <p>
                Communities/Families that share analytics with each other. We
                call it gamification of digital well-being.
              </p>
            </div>
          </div>
        </div>
        {/* Call to Action Section */}
        {<Subscribe></Subscribe>}
      </section>
      {/* Footer Section */}
      <footer className="footer">
        <div className="container">
          <p>© 2024 Mac'n'Chaos. #NeverForProfit.</p>
        </div>
      </footer>
    </>
  );
};

export default Bts;

import React, { useEffect, useState } from "react";
import Tweet from "../posts/Tweet.js";
import Pimage from "../posts/Pimage.js"
import Pvideo from "../posts/Pvideo.js";
import Loading from "../images/Loading.gif";
import listApi from "../../api/ListPostsApi.js";

const Blog = () => {
    
    const loadLimit = 5;
    const [postList, setPostList] = useState([]);
    var lastFetch = new Date().getTime();


    async function getSomePosts(clength = postList.length, loadLimit = 5) {
      const somePosts = await listApi.getNext(clength, loadLimit);
      setPostList(postList.concat(somePosts));
    };

    const handleScroll = (e) => {
      if (
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10
      ) {
        //Reached the end of page
        const ctime = new Date().getTime();
        const diff = (ctime-lastFetch)/1000; // time difference in seconds
        
        if(diff > 5) {
          //Last fetch was atleast 5 seconds before
          getSomePosts();
          lastFetch = ctime;
        }
      }
   }
   
    function convertToDate(timeStamp){
      var theDate = new Date(timeStamp.seconds*1000);
      return theDate.toString().slice(4,16) 
    }

    useEffect(()=>{
      listApi
        .getNext(0, loadLimit)
        .then((fetchedPostList) => {
          setPostList(fetchedPostList);
        });
    }, []);
  
    return (
      <div className="mobileHomePage" onScroll={handleScroll}>
        {
          postList.length>0 && (<p className="mobile-BlogHeading">Snippets</p>)
        }
        {postList.map((post) => {
          return (
            <div className="mobilePost">  
              <div className="mobilePostHeader">
                <div className="title">
                  <h2 style = {{"font-weight": "bold"}}>{post.title}</h2>
                </div>
                <div className="mobilePostExternalLink" onClick={
                  ()=>{
                    listApi.externalRedirect(post.id, post.private ?? false)
                  }
                }>
                  <button title="View and comment">
                    &#187;
                  </button>
                </div>
              </div>
            {
              post.postType === "macTweet" ?
                <Tweet content = {post.content} postId = {post.id} isPrivate = {post.private ?? false}/>:
              post.postType === "macImage" ?
                <Pimage content = {post.content} postId = {post.id} isPrivate = {post.private ?? false}/>:
              post.postType === "macVideo" ?
                <Pvideo content = {post.content} postId = {post.id} isPrivate = {post.private ?? false}/>:
              <></>
            }
            <div className="mobilePostFooter">
              <h4 className="author">@{post.author.name}</h4>
              <p className="date">{convertToDate(post.timeStamp)}</p>
            </div>
          </div>
          )
        })}
        {
          (postList.length !== 0 && !(listApi.noMorePostAvailable())) ?
          <button className="glow-on-hover" onClick={
              ()=>{
                getSomePosts()
              }
          }>
              Load More
          </button>:
          <></>
        }
        {
          (postList.length===0) &&
          (<div className="mobile-LoadingContainer">
            <p className="mobile-LoadingText">Loading Snippets....</p>
            <img className="mobile-LoadingImage" src={Loading} alt="loading"></img>
          </div>)
        }
        
      </div>
    );
};

export default Blog;

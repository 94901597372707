import listApi from "../../api/ListPostsApi.js";

function Pimage({content, postId, isPrivate}){
    // Determine the length of text for the snippet (e.g., 200 characters)
    const maxLength = 100;

    const isTrimmed = content.postText.length > maxLength;

    // Trim the content text to show only the snippet
    const snippet = isTrimmed ?
        `${content.postText.substring(0, maxLength).trim()}...` :
        content.postText;
    
    return (
        <>
            <div className="mobile-image-wrapper" >
                <img className="mobilePostImageContainer" src={content.image} alt={content.alt} />
            </div>
                {content.postText!==""&&(
                    <br></br>
                )}
            
            <div className="mobilePostTextContainer" style = {{'white-space':'pre-line'}}>
                <p>{snippet}</p>
                {isTrimmed &&
                <button className="mobilePostTextContainer-readMore" onClick={
                    ()=>{
                        listApi.externalRedirect(postId, isPrivate)
                      }
                }>
                    Read more
                </button>}
            </div>
        </>
        
    )
}
export default Pimage;
import React, { useEffect, useState } from "react";
import Tweet from "../posts/Tweet.js";
import Pimage from "../posts/Pimage.js"
import Pvideo from "../posts/Pvideo.js";
import Loading from "../images/Loading.gif";
import listApi from "../../api/ListPostsApi.js";

const Blog = () => {
    const loadLimit = 5;
    const [postList, setPostList] = useState([]);
    async function getSomePosts(clength = postList.length, loadLimit = 5) {
      const somePosts = await listApi.getNext(clength, loadLimit);
      setPostList(postList.concat(somePosts));
    };
   
    function convertToDate(timeStamp){
      var theDate = new Date(timeStamp.seconds*1000);
      return theDate.toString().slice(4,16) 
    }

    useEffect(()=>{
      listApi
        .getNext(0, loadLimit)
        .then((fetchedPostList) => setPostList(fetchedPostList));
    }, []);
  
    return (
      postList.length===0 ?
      <div className="LoadingContainer">
        <p className="LoadingText">Loading Blog....</p>
        <img className="LoadingImage" src={Loading} alt="loading"></img>
      </div>:
      <div className="homePage">
        {postList.map((post) => {
          return (
            <div className="post">
              <div className="postHeader">
                <div className="title">
                  <h2 style = {{"font-weight": "bold"}}>{post.title}</h2>
                </div>
                <div className="postExternalLink" onClick={
                  ()=>{
                    listApi.externalRedirect(post.id, post.private ?? false);
                  }
                }>
                  <button title="View and comment">
                    &#187;
                  </button>
                </div>
              </div>
              
            {
              post.postType === "macTweet" ?
                <Tweet content = {post.content}/>:
              post.postType === "macImage" ?
                <Pimage content = {post.content}/>:
              post.postType === "macVideo" ?
                <Pvideo content = {post.content}/>:
              <></>
            }
            <div className="postFooter">
              <h4 className="author">@{post.author.name}</h4>
              <p>{convertToDate(post.timeStamp)}</p>
            </div>
          </div>
          )
        })}
        {
          (postList.length !== 0 && !(listApi.noMorePostAvailable()))?
          <button className="glow-on-hover" onClick={
              ()=>{
                  getSomePosts()
              }
          }>
              Load More
          </button>:
          <></>
        }
      </div>
    );
};

export default Blog;
